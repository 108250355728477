import * as React from "react";
import { Routes, Route } from "react-router-dom";
import "./App.css";
import Home from './Home';
import TenYear from './TenYear';

function App() {
  return (
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/ten-years" element={<TenYear />} />
    </Routes>
  );
}

export default App;
