import React, { Component } from 'react';
// import { Grid, Navbar, Jumbotron, Button } from 'react-bootstrap';
// import { CSSTransitionGroup } from 'react-transition-group' // ES6
// import CSSTransitionGroup from 'react-transition-group/CSSTransitionGroup';
import Swipeable from 'react-swipeable';

// import logo from './logo.svg';
import './Home.css';
import Polaroid from './Polaroid';


const steps = [{
  title: 'My Angel',
  subTitle: 'About you, for you.',
  // text: 'Happy 5th Anniversary!',
  bgClass: 'bg-pink',
  topPictures: [{
    path: '/20140612_194509_tn.jpg',
  }, {
    path: '/image2_tn.jpg',
  }, {
    path: '/IMG_0044_tn.jpg',
  }],
  bottomPictures: [{
    path: '/IMG_0115_tn.jpg',
  }, {
    path: '/20140420_143357_tn.jpg',
  }, {
    path: '/20141027_152244_tn.jpg',
  }]
}, {
  title: 'You’re unique',
  subTitle: 'The only girl that met my crazy weird high standarts, I thought it was impossible and accepted the fate that I won’t have a wife, but you exceeded them and I love this about you.',
  bgClass: 'bg-blue',
  topPictures: [{
    path: '/20140902_120116_tn.jpg',
  }, {
    path: '/20140422_151827_tn.jpg',
  }, {
    path: '/20140422_165214_tn.jpg',
  }],
  bottomPictures: [{
    path: '/20140420_130017_tn.jpg',
  }, {
    path: '/20141005_135042_tn.jpg',
  }, {
    path: '/20170415_174852_tn.jpg',
  }]
}, {
  title: 'You’re caring',
  subTitle: 'You always care and think about other people, Sometimes have to stop you and say forget about those people they are not worth it, but you are a very warm and caring person even about totaly stranger people and I love this about you.',
  bgClass: 'bg-yellow ',
  topPictures: [{
    path: '/2013-12-31-1153_tn.jpg',
  }, {
    path: '/2013-12-31-1189_tn.jpg',
  }, {
    path: '/20140420_143518_tn.jpg',
  }],
  bottomPictures: [{
    path: '/20140613_230652_tn.jpg',
  }, {
    path: '/20140614_193025_tn.jpg',
  }, {
    path: '/20140718_174919_tn.jpg',
  }]
}, {
  title: 'You’re amazing mother',
  subTitle: "Being a mother is really hard, but you're doing an amazing job, our children are always busy with lessons, they are being taken care of, and they have a great example to follow (you) and I love this about you.",
  bgClass: 'bg-black',
  topPictures: [{
    path: '/20140727_192431_tn.jpg',
  }, {
    path: '/20150124_222535_tn.jpg',
  }, {
    path: '/20151223_221102_tn.jpg',
  }],
  bottomPictures: [{
    path: '/20141015_135412_tn.jpg',
  }, {
    path: '/20160125_220102_tn.jpg',
  }, {
    path: '/20160228_142542_tn.jpg',
  }]
}, {
  title: 'You’re loving',
  subTitle: 'You always care on how is our relationship state is currently and will pay attention to details and will do anything in your power to make sure we are the best couple ever and I love this about you.',
  bgClass: 'bg-grey',
  topPictures: [{
    path: '/20140722_160051_tn.jpg',
  }, {
    path: '/20140907_174957(0)_tn.jpg',
  }, {
    path: '/20141018_225530_tn.jpg',
  }],
  bottomPictures: [{
    path: '/20160214_134503_tn.jpg',
  }, {
    path: '/20161017_155055_tn.jpg',
  }, {
    path: '/20170401_144217_tn.jpg',
  }]
}, {
  title: 'You’re strong',
  subTitle: "All what we have been through was very though I don't know how any other person can put so much faith in my crazy ideas and risking so much, tolerating having no money to pay for food for our children and I love this about you.",
  bgClass: 'bg-green',
  topPictures: [{
    path: '/20161017_153407_tn.jpg',
  }, {
    path: '/20141018_225508_tn.jpg',
  }, {
    path: '/20170408_163546_tn.jpg',
  }],
  bottomPictures: [{
    path: '/20160228_231550_tn.jpg',
  }, {
    path: '/20161019_101913_tn.jpg',
  }, {
    path: '/20170507_185811_tn.jpg',
  }]
}, {
  title: 'You’re romantic',
  subTitle: "You're the spark in our realtionship without you I would still hate traveling and miss all the nice places and expirences we went through toghter and I love this about you.",
  bgClass: 'bg-pink',
  topPictures: [{
    path: '/toghter_tn.jpg',
  }, {
    path: '/IMG_2274_tn.jpg',
  }, {
    path: '/IMG_0338.jpg',
  }],
  bottomPictures: [{
    path: '/IMG_2267_tn.jpg',
  }, {
    path: '/IMG_0372_tn.jpg',
  }, {
    path: '/20151223_222045_tn.jpg',
  }]
}, {
  title: 'You’re family person',
  subTitle: "Family is the most important to you and you will do anything to protect it, and I love this about you.",
  bgClass: 'bg-blue',
  topPictures: [{
    path: '/family_tn.jpg',
  }, {
    path: '/IMG_4238_tn.jpg',
  }, {
    path: '/IMG_4226_tn.jpg',
  }],
  bottomPictures: [{
    path: '/IMG_3821_tn.jpg',
  }, {
    path: '/IMG_1074_tn.jpg',
  }, {
    path: '/IMG_0724_tn.jpg',
  }]
}, {
  title: 'You’re a winner',
  subTitle: "You don't like to lose, you have to be a winner in everything that you do if it's playing bowling, being a mother, or a wife. you have to be competitive and be the best in what you currently do and  I love this about you.",
  bgClass: 'bg-yellow',
  topPictures: [{
    path: '/IMG_2014_tn.jpg',
  }, {
    path: '/20170629_191957_tn.jpg',
  }, {
    path: '/20140709_181858_tn.jpg',
  }],
  bottomPictures: [{
    path: '/20161019_150112_tn.jpg',
  }, {
    path: '/20170214_132525_tn.jpg',
  }, {
    path: '/20170215_180339_tn.jpg',
  }]
}, {
  title: 'You’re beautiful',
  subTitle: "I fall in love with you from the first moment I saw you from our first dance and kiss, I already knew that you're the most beautiful women in the world and I'm so happy and lucky to have you and I love this about you.",
  bgClass: 'bg-black',
  topPictures: [{
    path: '/karina_tn.jpg',
  }, {
    path: '/IMG_2830_tn.jpg',
  }, {
    path: '/20170708_175340_tn.jpg',
  }],
  bottomPictures: [{
    path: '/20170614_194740_tn.jpg',
  }, {
    path: '/20170507_185445_tn.jpg',
  }, {
    path: '/20170507_144143_tn.jpg',
  }]
}, {
  title: 'You’re the reason',
  subTitle: 'I do everything and the one I want to spend my life with',
  bgClass: 'bg-grey',
  topPictures: [{
    path: '/reason_tn.jpg',
  }, {
    path: '/IMG_0017_tn.jpg',
  }, {
    path: '/IMG_4543_tn.jpg',
  }],
  bottomPictures: [{
    path: '/IMG_0045_tn.jpg',
  }, {
    path: '/IMG_0150_tn.jpg',
  }, {
    path: '/IMG_0048_tn.jpg',
  }]
}, {
  title: 'You’re the way you’re',
  subTitle: 'And I love this about you.',
  text: 'Before, now and for ever. -Mark',
  bgClass: 'bg-green',
  topPictures: [{
    path: '/20140829_193451_tn.jpg',
  }, {
    path: '/20140927_194902_tn.jpg',
  }, {
    path: '/20140420_130617_tn.jpg',
  }],
  bottomPictures: [{
    path: '/20160507_210344_tn.jpg',
  }, {
    path: '/20170102_162109_tn.jpg',
  }, {
    path: '/20170304_165101_tn.jpg',
  }]
}
];

class Home extends Component {
  pictureCount = 3;
  stepsTotal = steps.length;

  state = {
    step: 0,
    active: false,
  };

  setActive = (active, ms) => {
    setTimeout(() => this._isMounted && this.setState({ active }), ms);
  }

  changeStep = (step) => {
    this.setState({ active: false, step }, () => this.setActive(true, 200));
  }

  onSwipedLeft = () => {
    const nextStep = this.state.step + 1;
    
        if (nextStep < this.stepsTotal) {
          this.changeStep(nextStep);
        } else {
          //  this.props.history.push('/ten-years');
          window.location.replace('/ten-years');
          // this.changeStep(0);
        }
  }

  onSwipedRight = () => {
    const nextStep = this.state.step - 1;
    if (nextStep >= 0) {
      this.changeStep(nextStep);
    }
  }

  keyDown = (e) => {
      var key = e.key || e.keyCode;
      switch (key) {
        case 'ArrowLeft': case 37:
          this.onSwipedRight()
          break;
        case 'ArrowUp': case 38:
          break;
        case 'ArrowRight': case 39:
          this.onSwipedLeft();
          break;
        case 'ArrowDown': case 40:
          break;
        default:
          break;
      }
  }

  componentWillUnmount() {
    this._isMounted = false;
    document.removeEventListener('keydown', this.keyDown);
  }

  componentDidMount() {
    this._isMounted = true;
    this.setActive(true, 0);

    document.addEventListener('keydown', this.keyDown);
  }

  render() {
    const bodyWrapperClass = `body-wrapper ${steps[this.state.step].bgClass}`;
    const statementClasss = `statement-wrapper ${this.state.active ? 'active' : ''}`;
    const polaroidsClass = `polaroids-wrapper clearfix ${this.state.active ? 'active' : ''}`;

    return (
      <div className={bodyWrapperClass}>
        <div className="center-center-wrapper">
          <div className={polaroidsClass}>
            {steps[this.state.step].topPictures && (
              <ul className="polaroids">
                {steps[this.state.step].topPictures.map((picture, index) => (
                  <Polaroid key={picture.path} picture={picture} multipleBy={Math.floor(Math.random() * (this.pictureCount - 1) + 1)} />
                ))}
              </ul>
            )}
          </div>
          <Swipeable
            onSwipedLeft={this.onSwipedLeft}
            onSwipedRight={this.onSwipedRight}
            style={{width: '100%' }}
          >
            <div className={statementClasss}>
              <h1 className="main-title">{steps[this.state.step].title}</h1>
              <p className="sub-title">{steps[this.state.step].subTitle} <img alt="" src="/heart.png" className="heart-image" /></p>
              {steps[this.state.step].text && (
                <p className="sub-title">{steps[this.state.step].text}</p>
              )}

              <div className="sr-bullets">
                {steps.map((step, index) => (
                    <div key={index} className="sr-bullet-wrapper" onClick={() => this.changeStep(index)}>
                      <div className={`sr-bullet ${this.state.step === index && 'sr-bullet-active'}`}></div>
                    </div>  
                ))}
              </div>
            </div>
          </Swipeable>
          <div className={polaroidsClass}>
            {steps[this.state.step].bottomPictures && (
              <ul className="polaroids">
                {steps[this.state.step].bottomPictures.map((picture, index) => (
                  <Polaroid key={picture.path} picture={picture} multipleBy={Math.floor(Math.random() * (this.pictureCount - 1) + 1)} />
                ))}
              </ul>
            )}
          </div>
        </div>
      </div>
    );
  }
}

export default Home;
