import React, { Component } from 'react';
// import { Grid, Navbar, Jumbotron, Button } from 'react-bootstrap';
// import { CSSTransitionGroup } from 'react-transition-group' // ES6
// import CSSTransitionGroup from 'react-transition-group/CSSTransitionGroup';
import Swipeable from 'react-swipeable';
import party from "party-js";

// import logo from './logo.svg';
import './Home.css';
import Polaroid from './Polaroid';


const steps = [
{
  title: 'Year One (2012) - The Marriage Year',
  subTitle: 'We were by ourselves (no kids) called each other boyfriend & girlfrined, and we had our wedding and a lot of fun!',
  bgClass: 'bg-pink',
  topPictures: [{
    path: '/2012/177525_3246898861575_1983403571_o.jpeg',
  }, {
    path: '/2012/319829_3104535942591_1526670399_n.jpeg',
  }, {
    path: '/2012/337065_2535571678840_1730722165_o.jpeg',
  }],
  bottomPictures: [{
    path: '/2012/411328_3086628694921_777971214_o.jpeg',
  }, {
    path: '/2012/135892_3708588643531_116661245_o.jpeg',
  }, {
    path: '/2012/461390_295276940547127_1318221868_o.jpeg',
  }]
},
{
  title: 'Year Two (2013) - The Family Year',
  subTitle: 'Now we are married and a family (3) raising a child and figuring out how to be a family is though! But we did it!',
  bgClass: 'bg-blue',
  topPictures: [{
    path: '/2013/67524_4573411583564_1725982029_n.jpeg',
  }, {
    path: '/2013/39273_4466197663283_944541222_n.jpeg',
  }, {
    path: '/2013/922373_4662905100846_1910933015_o.jpeg',
  }],
  bottomPictures: [{
    path: '/2013/1400527_10200692312740446_837062197_o.jpeg',
  }, {
    path: '/2013/1491347_10201042642978483_1814207975_o.jpeg',
  }, {
    path: '/2013/2013-12-31-1185.jpeg',
  }]
},
{
  title: 'Year Three (2014) - The Learn How To Be A Parent Year',
  subTitle: 'Having fun with Richard, as you can see he likes programming with no pants on. Expecting a new baby!',
  bgClass: 'bg-yellow ',
  topPictures: [{
    path: '/2014/20140727_113003.jpeg',
  }, {
    path: '/2014/IMG_6888.jpeg',
  }, {
    path: '/2014/20150101_004017.jpeg',
  }, {
      path: '/2014/20140610_212822.jpeg',
  }],
  bottomPictures: [{
    path: '/2014/20141204_203929-COLLAGE.jpeg',
  }, {
    path: '/2014/20140722_142729-COLLAGE.jpeg',
  }, {
    path: '/2014/20140614_213924.jpeg',
  }, {
    path: '/2014/20140709_181813-COLLAGE.jpeg',
  }]
},
{
  title: 'Year Four (2015) - The Year Of Emily',
  subTitle: 'Emily is here! no money, no job, living with the parents and still risking it all for our love!',
  bgClass: 'bg-black',
  topPictures: [{
    path: '/2015/10403555_10203395862847509_1950162717004243329_n.jpeg',
  }, {
    path: '/2015/11257936_10204143288532684_4728516886425384251_n.jpeg',
  }, {
    path: '/2015/20150121_090231-COLLAGE.jpeg',
  }, {
    path: '/2015/IMG_0053.jpeg',
  },{
    path: '/2015/IMG_1330.jpeg',
  },
  {
    path: '/2015/IMG_1958.jpeg',
  }],
  bottomPictures: [{
    path: '/2015/20150124_222535.jpeg',
  }, {
    path: '/2015/IMG_0039.jpeg',
  }, {
    path: '/2015/IMG_0045.jpeg',
  }, {
    path: '/2015/IMG_0078.jpeg',
  }, {
    path: '/2015/IMG_1242.jpeg',
  }]
},
{
  title: 'Year Five (2016) - The Year Of Travel & Sweets',
  subTitle: 'We are now (4) everything is more complicated, but we found some time for us to travel which I hated before, and you loved!',
  bgClass: 'bg-grey',
  topPictures: [{
    path: '/2016/10271628_10205651633920376_8189172553970685531_n.jpeg',
  }, {
    path: '/2016/13220933_10206099729442484_9067310538086233345_n.jpeg',
  }, {
    path: '/2016/13263929_10206099737202678_6911227621347530461_n.jpeg',
  },{
    path: '/2016/20161223_171154.jpeg',
  },{
    path: '/2016/20160402_184251.jpeg',
  }],
  bottomPictures: [{
    path: '/2016/13412205_10206269522087194_669088967922726217_o.jpeg',
  }, {
    path: '/2016/IMG_4262.jpeg',
  }, {
    path: '/2016/20160609_091814.jpeg',
  },
  {
    path: '/2016/20160515_113224.jpeg',
  },
  {
    path: '/2016/20160510_151735.jpeg',
  }]
},
{
  title: 'Year Six (2017) - The Year Of Standing On Our Feet',
  subTitle: 'Getting into the rhytem of family, (First job, first car, first year of financial stability) and still dreaming big to improve our life.',
  bgClass: 'bg-green',
  topPictures: [{
    path: '/2017/20170930_134201.jpeg',
  }, {
    path: '/2017/20171001_161505.jpeg',
  }, {
    path: '/2017/20171014_133936_001.jpeg',
  },{
    path: '/2017/20248060_1559116424163166_383415632117435125_o.jpeg',
  }],
  bottomPictures: [{
    path: '/2017/20171015_155907.jpeg',
  }, {
    path: '/2017/20171104_144314.jpeg',
  }, {
    path: '/2017/20171231_231622.jpeg',
  }, {
      path: '/2017/22829822_1675930732481734_3817078130819397506_o.jpeg'
  }]
},
{
  title: 'Year Seven (2018) - Work & Fun, Just Be Happy!',
  subTitle: 'Continue to travel, love and play with each other <3',
  bgClass: 'bg-pink',
  topPictures: [{
    path: '/2018/20181013_163602-ANIMATION.gif',
  }, {
    path: '/2018/20181028_154418.jpeg',
  }, {
    path: '/2018/20181209_203820.jpeg',
  }],
  bottomPictures: [{
    path: '/2018/20181210_165703.jpeg',
  }, {
    path: '/2018/20181227_183650.jpeg',
  }, {
    path: '/2018/IMG_1515.jpeg',
  }, {
      path: '/2018/IMG_1441-ANIMATION.gif',
  }]
},
{
  title: 'Year Eight (2019) - Finally Moved Out',
  subTitle: 'Richard goes to private school, we rent an apartment, continue to travel, laugh and enjoy.',
  bgClass: 'bg-blue',
  topPictures: [{
    path: '/2019/64320820_2771727922902004_3905235795987922944_n.jpeg',
  }, {
    path: '/2019/IMG_0824.jpeg',
  }, {
    path: '/2019/IMG_0969.jpeg',
  }],
  bottomPictures: [{
    path: '/2019/IMG_20190907_102321_209.jpeg',
  }, {
    path: '/2019/IMG_1164.jpeg',
  }, {
    path: '/2019/IMG_1100.jpeg',
  }]
},
{
  title: 'Year Nine (2020) - Make It Better',
  subTitle: 'Continue to grow, continue to love, continue to be joyful!',
  bgClass: 'bg-yellow',
  topPictures: [{
    path: '/2020/IMG_8650.jpeg',
  }, {
    path: '/2020/IMG_8565.jpeg',
  }, {
    path: '/2020/IMG_8394.jpeg',
  }],
  bottomPictures: [{
    path: '/2020/IMG_8366.jpeg',
  }, {
    path: '/2020/IMG_7333.jpeg',
  }, {
    path: '/2020/812DF562-C18F-4FF8-B530-E51411F56047.jpeg',
  }]
},
{
  title: 'Year Ten (2021) - Dreams Coming To Life',
  subTitle: 'Better Weather? Check. Big Villa? Check. Pay For It Easilly? Check. Re-organising our life in our own terms!',
  bgClass: 'bg-pink',
  topPictures: [{
    path: '/2021/IMG_0957.jpeg',
  }, {
    path: '/2021/IMG_0977.jpeg',
  }, {
    path: '/2021/IMG_1216.jpeg',
  }],
  bottomPictures: [{
    path: '/2021/IMG_9657.jpeg',
  }, {
    path: '/2021/IMG_9963.jpeg',
  }, {
    path: '/2021/IMG_9894.jpeg',
  }]
},
];

class TenYear extends Component {
  pictureCount = 3;
  stepsTotal = steps.length;

  state = {
    step: 0,
    active: false,
  };

  setActive = (active, ms) => {
    setTimeout(() => this._isMounted && this.setState({ active }), ms);
  }

  changeStep = (step) => {
    this.setState({ active: false, step }, () => this.setActive(true, 200));
  }

  onSwipedLeft = () => {
    const nextStep = this.state.step + 1;
    
        if (nextStep < this.stepsTotal) {
          this.changeStep(nextStep);
        } else {
          this.changeStep(0);
        }
  }

  onSwipedRight = () => {
    const nextStep = this.state.step - 1;
    if (nextStep >= 0) {
      this.changeStep(nextStep);
    }
  }

  keyDown = (e) => {
      var key = e.key || e.keyCode;
      switch (key) {
        case 'ArrowLeft': case 37:
          this.onSwipedRight()
          break;
        case 'ArrowUp': case 38:
          break;
        case 'ArrowRight': case 39:
          this.onSwipedLeft();
          break;
        case 'ArrowDown': case 40:
          break;
        default:
          break;
      }
  }

  onClick = (e) => {
    e.preventDefault();
    party.confetti(e);
  }

  componentWillUnmount() {
    this._isMounted = false;
    document.removeEventListener('keydown', this.keyDown);
    document.body.removeEventListener("click", this.onClick);

  }

  componentDidMount() {
    this._isMounted = true;
    this.setActive(true, 0);

    document.addEventListener('keydown', this.keyDown);
    document.body.addEventListener("click", this.onClick);
  }

  render() {
    const bodyWrapperClass = `body-wrapper ${steps[this.state.step].bgClass}`;
    const statementClasss = `statement-wrapper ${this.state.active ? 'active' : ''}`;
    const polaroidsClass = `polaroids-wrapper clearfix ${this.state.active ? 'active' : ''}`;

    return (
      <div className={bodyWrapperClass}>
        <div className="center-center-wrapper">
          <div className={polaroidsClass}>
            {steps[this.state.step].topPictures && (
              <ul className="polaroids">
                {steps[this.state.step].topPictures.map((picture, index) => (
                  <Polaroid key={picture.path} picture={picture} multipleBy={Math.floor(Math.random() * (this.pictureCount - 1) + 1)} />
                ))}
              </ul>
            )}
          </div>
          <Swipeable
            onSwipedLeft={this.onSwipedLeft}
            onSwipedRight={this.onSwipedRight}
            style={{width: '100%' }}
          >
            <div className={statementClasss}>
              <h1 className="main-title">{steps[this.state.step].title}</h1>
              <p className="sub-title">{steps[this.state.step].subTitle} <img alt="" src="/heart.png" className="heart-image" /></p>
              {steps[this.state.step].text && (
                <p className="sub-title">{steps[this.state.step].text}</p>
              )}

              <div className="sr-bullets">
                {steps.map((step, index) => (
                    <div key={index} className="sr-bullet-wrapper" onClick={() => this.changeStep(index)}>
                      <div className={`sr-bullet ${this.state.step === index && 'sr-bullet-active'}`}></div>
                    </div>  
                ))}
              </div>
            </div>
          </Swipeable>
          <div className={polaroidsClass}>
            {steps[this.state.step].bottomPictures && (
              <ul className="polaroids">
                {steps[this.state.step].bottomPictures.map((picture, index) => (
                  <Polaroid key={picture.path} picture={picture} multipleBy={Math.floor(Math.random() * (this.pictureCount - 1) + 1)} />
                ))}
              </ul>
            )}
          </div>
        </div>
      </div>
    );
  }
}

export default TenYear;
