import React, { Component } from 'react';
import ImageZoom from 'react-medium-image-zoom'

class Polaroid extends Component {
    state = {
        active: false,
        // isZoomed: false,
    }
    componentDidMount() {
        this._isMounted = true;
        setTimeout(() => this._isMounted && this.setState({ active: true }), 150 * this.props.multipleBy);
    }
    componentWillUnmount() {
        this._isMounted = false;
    }
    render() {
        const picture = this.props.picture;
        const className = `single-polaroid ${this.state.active ? 'active' : '' }`;
        const randomRange = Math.floor(Math.random() * 11) - 5;
        const randomRotation = { 
            Transform: `rotate(${randomRange}deg)`, 
            WebkitTransform: `rotate(${randomRange}deg)`,
            MozTransform: `rotate(${randomRange}deg)`, 
        };
        // const scaleStyle = {
        //     Transform: 'scale(1.25)',
        //     WebkitTransform: 'scale(1.25)',
        //     MozTransform: 'scale(1.25)',
        // }
        return (
            <li className={className} style={randomRotation}>
                {/* <a style={this.state.isZoomed ? scaleStyle : {}}> */}
                <a>
                    {/* <img alt="" src={picture.path} /> */}
                        {/* onZoom={() => this.setState({ isZoomed: true })} */}
                        {/* onUnzoom={() => this.setState({ isZoomed: false })} */}
                    <ImageZoom
                        zoomMargin={10}
                        image={{
                            src: picture.path,
                            alt: '',
                        }}
                        zoomImage={{
                            src: picture.path.replace('_tn', ''),
                            alt: '',
                        }}
                        defaultStyles={{
                            overlay: {
                                backgroundColor: 'rgba(0,0,0,1)'
                            },
                        }}
                    />
                </a>
            </li>
        )
    }
}

export default Polaroid;